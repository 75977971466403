import "./set-public-path";
import "./plugins/fetching";
import "./assets/css/style.css";
import "./assets/css/main.css";
import "./assets/css/common.css";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import { BrowserTracing } from "@sentry/tracing";
import * as SentryVue from '@sentry/vue'
import IdleVue from "idle-vue";
import json5 from "json5";
import singleSpaVue from "single-spa-vue";
import Vue from "vue";

import pjson from "../package.json";
import { getUserDetails, getMerchantConfig } from "../src/services/common";

import App from "./App.vue";
import { logout, getToken, refreshToken } from "./lib/auth";
import { i18n } from "./plugins/i18n";
import router from "./router";
import store from "./store";
import "./plugins/vuetify";

Vue.config.productionTip = false;
const eventsHub = new Vue();
let user = Vue.observable({ data: {} });
Object.defineProperty(Vue.prototype, "$user", {
  get() {
    return user.data;
  },
  set(value) {
    user.data = value;
  }
});
const n_ws_notify = Vue.observable({ n_ws_notify: {} });
Object.defineProperty(Vue.prototype, "$n_ws_notify", {
  get() {
    return n_ws_notify.n_ws_notify;
  },
  set(value) {
    n_ws_notify.n_ws_notify = value;
  }
});

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {}
      });
    },
    created: async function() {
      Vue.prototype.$config = {};
      Vue.prototype.$isKeycloakAuth = this.isKeycloakAuth;
      if (this.isKeycloakAuth) {
        Vue.prototype.$token = this.idToken;
        Vue.prototype.$refreshToken = this.refreshToken;
        Vue.prototype.$logout = this.logout;
        if (this.idToken()) {
          await this.setUserDetails();
        }
      } else {
        Vue.prototype.$token = getToken;
        Vue.prototype.$logout = logout;
        Vue.prototype.$refreshToken = refreshToken;
      }
      Vue.prototype.$noNavBar = this.noNavBar;
      Vue.prototype.$serverOrigin = this.serverOrigin;
      Vue.prototype.$sentryUrl = this.sentryUrl;
      const lang = this.$route.path.slice(1, 3);
      if (lang === "en" || lang === "ar") {
        this.$i18n.locale = lang;
      }
      this.setLang();
      this.$vuetify.rtl = this.$i18n.locale === "ar";
      this.$vuetify.theme["nfo-main-color"] = "F77C1A";
      this.$vuetify.theme["fo-main-color"] =
        this.foodOrderingColor || "#f77c1a";
      Sentry.setTag("microfrontend", "navigation");
      Sentry.init({
        dsn: this.sentryUrl,
        integrations: [
          new Integrations.Vue({ Vue, attachProps: true, logErrors: true }),
          new BrowserTracing({
            tracePropagationTargets: [
              localStorage.getItem("MERCHANT_ID"),
              /^\//,
            ],
            routingInstrumentation: SentryVue.vueRouterInstrumentation(router),
          }),
        ],
        release: `navigation@${pjson.version}`,
        tracesSampleRate: this.traceSampleRate,
        ignoreErrors: this.ignoredSentryErrors,
        beforeSend: this.checkSentryErrors,
        sampleRate: this.sentrySampleRate,
      });
    },
    computed: {
      state() {
        return this.state;
      },
      ws_notification() {
        return this.ws_notification;
      }
    },
    watch: {
      "$i18n.locale": function() {
        this.$vuetify.rtl = this.$i18n.locale === "ar";
      },
      ws_notification: {
        immediate: true,
        handler() {
          Vue.set(Vue.prototype, "$n_ws_notify", this.ws_notification);
          if (this.ws_notification.length) {
            this.showMessage();
          }
        }
      },
      state: {
        deep: true,
        async handler() {
          await this.setUserDetails();
        }
      }
    },
    methods: {
      setLang() {
        const translations = this.translations();
        this.$i18n.setLocaleMessage("en", translations.en);
        this.$i18n.setLocaleMessage("ar", {
          ...this.arabicTranslation,
          ...translations.ar
        });
      },
      showMessage() {
        let event =
          json5.parse(
            this.ws_notification.find(ws_notification => ws_notification)
          ) || {};
        if (event.content?.message) {
          this.$root.$emit("snackbar", {
            text: event.content.message,
            color: event.content.alert,
            link: event.content.data.file
          });
        }
      },
      async setUserDetails() {
        try {
          const config = (await getMerchantConfig()).data;
          Vue.prototype.$config = config;
          getUserDetails().then(({ data }) => {
            Vue.prototype.$user = data;
            const idletime = !config.enable_session_timeout
              ? 24 * 60 * 60 * 1000
              : localStorage.getItem("VUE_APP_SESSION_IDLE") || 1200000;
            Vue.use(IdleVue, {
              eventEmitter: eventsHub,
              store,
              idleTime: idletime,
              startAtIdle: false
            });
            Vue.set(
              Vue.prototype,
              "$enable_session_timeout",
              config.enable_session_timeout
            );
          });
        } catch (error) {
          console.log("Error while fetching user details", error);
        }
      },
    },
    router,
    i18n,
    store
  }
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
